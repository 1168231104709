import './App.css';
import RouterPage from "./Router";

function App() {
  return (
    <div>
        <RouterPage/>

    </div>
  );
}

export default App;
