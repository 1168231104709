import React from 'react'

//This is Top Row Section
import NavBar from "./NavBar2";
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import RouterPage from "./Router";
import Col from "react-bootstrap/Col";

function Resume() {
    return (

        <Container className={"mb-3"} style={{color:"White"}}>
            <div className="col-4 mx-auto mb-5">
                </div>



                <div className="col-xs-6 mx-auto mb-3">
                    <div className="card" id={"PortfolioCards"}>
                            <div className="card-body">
                                <h5 className="card-title" style={{borderBottom:"3px solid white"}}> Job Application Tracker </h5>
                                <p className="card-text">
                                    The goal of this project is to simplify and optimize the job application process for users.
                                    The project allows users to store job opportunities in boards, and create multiple boards for different
                                    job searches. Additionally, users can save interview information for each job and track their interview history.
                                    This project aims to streamline the job application process and
                                    provide users with an organized platform to manage their job search effectively. We are still in current development.
                                </p>
                                <p className="card-text">
                                    <h6> Technology Used: AWS RDS, React, Bootstrap, Node.js, Express, MySQL </h6>
                                </p>

                            </div>
                    </div>
                </div>
                <div className="col-xs-6 mx-auto mb-3" >
                    <div className="card" id={"PortfolioCards"}>
                            <div className="card-body">
                                <h5 className="card-title" style={{borderBottom:"3px solid white"}}> Flight Price Tracker</h5>
                                <p className="card-text">
                                    The project involved accessing flight data and prices via an API for flights departing from ORD to
                                    28 different airports. Data was consistently logged for about 45 days and
                                    is now being tested using Excel to identify any patterns in pricing based on flight data. Contact me if you
                                    would like to use the dataset.
                                </p>
                                <p className="card-text">
                                    <h6> Technology Used: C#, RapidAPI's </h6>
                                </p>
                            </div>
                    </div>
                </div>

                <div className="col-xs-6 mx-auto mb-3" >
                    <div className="card" id={"PortfolioCards"} >
                            <div className="card-body" >
                                <h5 className="card-title" style={{borderBottom:"3px solid white"}}>ALDI PTO TRACKER</h5>
                                <p className="card-text" >As part of a group of four, I participated in the development of a Paid Time Off (PTO)
                                    tracker for Aldi employees. We used agile methodology and divided the project into three sprints.
                                    Upon completion of the project, we presented it to Aldi and received feedback. This project helped me gain experience in
                                    new technologies and working with a client. A video demo is available if interested.
                                </p>
                                <p className="card-text">
                                    <h6> Technology Used: Node.js, Pug/Jade, MySQL, PHPMyAdmin, Express </h6>
                                </p>
                            </div>
                    </div>
                </div>


        </Container>


    );
}

export default Resume;