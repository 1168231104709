import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';


function CollapsibleExample() {
  return (
    <Navbar sticky="top" collapseOnSelect expand="lg" className="navbar-dark">
          <Container fluid>
            <Navbar.Brand className="me-auto" href="/" > CRISTIAN LARA </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav ">
              <Nav className="ms-auto">
                <Nav.Link href="/About">ABOUT</Nav.Link>
                <Nav.Link href="/Portfolio">PORTFOLIO</Nav.Link>
                <Nav.Link href="/Resume" >RESUME</Nav.Link>
                <Nav.Link href="https://www.linkedin.com/in/cristianlara621/">LINKEDIN</Nav.Link>
                <Nav.Link href="/Contact">CONTACT</Nav.Link>

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
  );
}

export default CollapsibleExample;