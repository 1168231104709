import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import NavBar from "./NavBar2";

import Home from "./Home";
import About from "./About";
import Portfolio from "./Resume";
import Contact from "./Contact";
import Resume from "./Portfolio";

import React from "react";


function RouterPage() {
    return (
        <Router>
            <div>
                <NavBar/>

                <div className="Content">
                    <Routes>
                        <Route path={"/"} element={<Home/>}>  </Route>
                        <Route path={"/About"} element={<About/>}>  </Route>
                        <Route path={"/Portfolio"} element={<Portfolio/>}>  </Route>
                        <Route path={"/Contact"} element={<Contact/>}>  </Route>
                        <Route path={"/Resume"} element={<Resume/>}>  </Route>
                        {/*<Route path={"*"} element={<Resume/>}>  </Route>*/}
                    </Routes>
                </div>


            </div>

        </Router>
    );
}

export default RouterPage;