import React from 'react'

//This is Top Row Section
import NavBar from "./NavBar2";
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import RouterPage from "./Router";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

function Home() {
    return (

        <section style={{backgroundColor: "black",color:"white"}}>
            <div className="container py-5">
                <div className="main-timeline">
                    <div className="timeline left">
                        <div className="card">
                            <div className="card-body p-4">
                                <h3>May 2023</h3>
                                <h5> Aurora University </h5>
                                <p className="mb-0">

                                    B.S. In Computer Science and Mathematics
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="timeline right">
                        <div className="card">
                            <div className="card-body p-4">
                                <h3> November 2022 </h3>
                                <h5> JPMorgan Chase & Co. </h5>
                                <h6> Code For Good </h6>
                                <p className="mb-0">
                                    I participated in JPMorgan Chase & Co.'s Code for Good hackathon,
                                    where I worked with a team of four to develop a membership website solution for
                                    Zora's House. Our goal was to scale the website to increase community engagement
                                    and provide a collaborative space for like-minded individuals. This experience gave me an
                                    opportunity to work with a team/client, and solve real-world problems.
                                </p>

                                    <h6>Some Technologies we used:</h6> AWS, React, HTML, Node.js, Express

                            </div>
                        </div>
                    </div>
                    <div className="timeline left">
                        <div className="card">
                            <div className="card-body p-4">
                                <h3> July-August 2022 </h3>
                                <h5> AT&T Technology Academy</h5>
                                <h6> Network Engineer Externship </h6>
                                <p className="mb-0">
                                    During my externship at AT&T, I learned about Agile principles, as well as how AT&T stays Agile.
                                    I also gained an understanding of cloud computing fundamentals, and an overview of 5G technology and its core architecture.

                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="timeline right">
                        <div className="card">
                            <div className="card-body p-4">
                                <h3> Nov. 2019 - Present </h3>
                                <h5> Whole Foods Market</h5>
                                <h6> E-Commerce Shopper</h6>
                                <p className="mb-0">
                                    Worked as an E-Commerce (online grocery fulfillment) associate.
                                    My primary responsibility was to prepare customer orders for pickup or delivery.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="timeline left">
                        <div className="card">
                            <div className="card-body p-4">
                                <h3> Resume </h3>
                                <h5> <a href={"Lara.pdf"} download> Download </a> </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Home;