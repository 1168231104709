import React, {useEffect} from 'react'

//This is Top Row Section
import NavBar from "./NavBar2";
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import RouterPage from "./Router";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";

import PhotoC from "./WebsitePhoto2.jpeg";
import {useNavigate} from "react-router";

function About() {
    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            navigate('/About')
        }, 6000)
    }, [])
    return (

        <Container fluid id={"HomePage"} className={"vh-100 d-flex align-items-center justify-content-center"} >

            <Row  id={"Home"} >
                <Col xs={12} md={6} className={"d-flex align-items-center justify-content-center"} >
                    <img src={PhotoC} alt={"Self portrait"} style={{height:250,width:250,borderRadius:150}}/>
                </Col>
                <Col xs={12} md={6} className={"d-flex align-items-center justify-content-center"} style={{marginTop:20}}>
                    <p>
                        Hello, I'm


                        <span id={"LandingPageH1"}><h1> Cristian Lara</h1></span>



                            Aurora University 23



                    </p>
                </Col>
            </Row>


        </Container>

    );
}

export default About;