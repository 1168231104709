import React from 'react'

//This is Top Row Section
import NavBar from "./NavBar2";
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import RouterPage from "./Router";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";

import PhotoC from "./WebsitePhoto2.jpeg";

function About() {
    return (

        <Container fluid id={"HomePage"} className={"vh-100 d-flex align-items-center justify-content-center"} >

            <Row  id={"About"} >
                <Col xs={12} md={6} className={"d-flex align-items-center justify-content-center"} >
                    <img src={PhotoC} alt={"Self portrait"} style={{height:250,width:250,borderRadius:150}}/>
                </Col>
                <Col xs={12} md={6} className={"d-flex align-items-center justify-content-center"} style={{marginTop:20}}>
                    <p>
                    <p>Hello! I am Cristian Lara a current senior at Aurora University majoring in Computer Science and
                    Mathematics. After my studies I'll be joining AT&T as a member of their Technology Development Program in Chicago.
                    </p>
                    <p>Some of my most recent experiences include working alongside 3 colleagues to help
                    a client develop a Personal Time Off tracker for their employees. This project was completed using
                    <span> Node.js</span>,<span> Pug/Jade</span>, and <span> MySQL</span>. Although we didn't get to implement the database in the cloud,
                        we did learn a lot about using <span> AWS RDS </span> services to do so.
                    </p>
                        <p>


                        <span><Link to={"/Portfolio"} style={{ color: '#473BF0' }}><button
                            type="button" className="btn btn-light" style={{fontFamily:"sans-serif",fontWeight:"bolder"}}>MORE PROJECTS</button></Link></span>
                        </p>

                    </p>
                </Col>
            </Row>


        </Container>

    );
}

export default About;