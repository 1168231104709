import React from 'react'

//This is Top Row Section
import NavBar from "./NavBar2";
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import RouterPage from "./Router";
import Col from "react-bootstrap/Col";
import EmailLogo from "./EmailLogo.png";
import LinkedLogo from "./Linked.dms";

function Contact() {
    return (
        <Container fluid={"xs"} id={"HomePage"}  className={"vh-100 d-flex align-items-center justify-content-center"}>
            <Row id={"Contact"}  >
                <Row style={{marginLeft:20}}>
                    <div id={"BorderOnTOp"}/>
                    <br/>
                    <br/>
                    <h3 style={{fontSize:"250%"}}>Cristian Lara </h3>
                    <a href="mailto:laracristian7127@gmail.com" style={{color:"white",fontFamily:"sans-serif",fontWeight:"bold",fontSize:"150%"}}> <img src={EmailLogo} alt={"Email Logo"} id={"con-Logo"}/> laracristian7127@gmail.com </a>
                </Row>

                <br/>
                <br/>
                <Row style={{marginLeft:20,color:"white",fontFamily:"sans-serif",fontWeight:"bold",fontSize:"200%"}}>
                    <p> You can also follow me on

                        <p style={{color:"white",fontFamily:"sans-serif",fontWeight:"bold",fontSize:"80%"}}> <img src={LinkedLogo} alt={"Email Logo"} id={"con-Logo"}/> LinkedIn </p>



                    </p>


                </Row>



            </Row>

        </Container>


    );
}

export default Contact;